div.flex-table{
    display: flex;
    flex: 1 !important;
    justify-content: flex-start;
    border-collapse: collapse;
    overflow:auto;
}

div.flex-table > div.flex-headers {
    display: flex;
    position: sticky;
    top: 10px;
    left: 0;
    right: 0;
}
div.flex-table > div.flex-headers > div {
    flex: 1 !important;
    border: solid .5px #000 ;
}

div.flex-table > div.flex-row {
    display: flex;
}

div.flex-table > div.flex-row > div {
    flex: 1 !important;
    border: solid .5px #000 ;
}

@media (max-width: 576px){
    div.flex-table{
        flex-direction: row !important;
    }

    div.flex-table > div.flex-headers,
    div.flex-table > div.flex-row{
        flex-direction: column !important;
    }
}
@media (min-width: 577px){
    div.flex-table{
        flex-direction: column !important;
    }

    div.flex-table > div.flex-headers,
    div.flex-table > div.flex-row{
        flex-direction: row !important;
    }
}